/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { HiArrowNarrowRight } from 'react-icons/hi';

import './associatesCards.scss';

const AssociatesCardItem = ({ props }) => (
  <div className="col-md-4">
    <div className="main-professors-container">
      <Link to={`/${props.slug}`}>
        <div
          className="img-wrapper"
          style={{ backgroundImage: `url(${props.image.url})` }}
        />
        <div className="text-content">
          <p className="text-1">{props.name}</p>
          <p className="text-1">{props.surname}</p>
          <p className="text-2">{props.mail}</p>
          <div className="text-3-wrapper">
            <p className="text-3">
              {props.readMore}
              <HiArrowNarrowRight className="arrow-right-long my-auto" />
            </p>
          </div>
        </div>
      </Link>
    </div>
  </div>
);
AssociatesCardItem.propTypes = {
  data: PropTypes.shape({
    professorsTitle: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    biographyTitle: PropTypes.string.isRequired,
    biography: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    mail: PropTypes.string.isRequired,
    readMore: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default AssociatesCardItem;
