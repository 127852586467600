import React from 'react';
import PropTypes from 'prop-types';

import './cycles.scss';

const Cycles = (props) => (
  <div className="container main-container col-lg-12">
    <div className="row title-container d-flex justify-content-center mb-5">
      <div className="title col-sm-12 col-lg-4">
        <h2>{props.data.title}</h2>
      </div>
      <div className="description pt-2 col-sm-12 col-lg-4">
        <p>{props.data.description}</p>
      </div>
    </div>
    <div className="d-lg-flex mx-auto gap-3 justify-content-center">
      {props.data.cyclesCard.slice(0, 2).map((item) => (
        <div className="cycles-container col-sm-12 col-lg-4" key={item.id}>
          <div className="col-12 main-wrapper ps-lg-5">
            <span className="left-border" />
            <h5>{item.cycleTitle}</h5>
            <p>{item.cycleContent}</p>
            <span className="arrow down d-sm-block" />
          </div>
        </div>
      ))}
    </div>
    <div className="d-lg-flex mx-auto gap-3 justify-content-center">
      {props.data.cyclesCard.slice(2, 4).map((item) => (
        <div className="cycles-container col-sm-12 col-lg-4" key={item.id}>
          <div className="col-12 main-wrapper ps-lg-5">
            <span className="left-border" />
            <h5>{item.cycleTitle}</h5>
            <p>{item.cycleContent}</p>
            <span className="arrow down d-sm-block" />
          </div>
        </div>
      ))}
    </div>
  </div>
);

Cycles.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    cyclesCard: PropTypes.arrayOf(
      PropTypes.shape({
        cycleTitle: PropTypes.string.isRequired,
        cycleContent: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default Cycles;
