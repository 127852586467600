import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import '../../AssociatesDetailPage/associatesDetails.scss';

const Regulation = (props) => (
  <div>
    <div className="container main-container col-12 col-lg-6 my-5">
      <div className="text-container col-12 col-lg-8 mx-auto">
        <h3 className="col-12 col-lg-9 mb-4 mb-lg-5 fs-5">
          {props.data.title}
        </h3>
        <ReactMarkdown className="about-content mt-4 ps-1 pt-2 mx-auto">
          {props.data.content}
        </ReactMarkdown>
      </div>
    </div>
  </div>
);
Regulation.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default Regulation;
