/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';

import './heroPage.scss';

const HeroImage = (props) => (
  <div className="card hero-container border-0 text-white">
    <img src={props.data.image.url} className="card-img" alt="..." />
    <div className="card-img-overlay mt-auto mx-auto col-lg-8 col-xl-7 col">
      <h5 className="card-title hero-title-one">{props.data.titleTwo}</h5>
      <a className="card-text hero-title-two d-none d-lg-block" href={props.data.externLink}>
        {props.data.title}
      </a>
      {/* visible on mobile */}
      <a className="card-text hero-title-two-mobile d-lg-none" href={props.data.externLink}>{props.data.title}</a>
    </div>
  </div>
);
HeroImage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    externLink: PropTypes.string.isRequired,
    titleTwo: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default HeroImage;
