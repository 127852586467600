/* eslint-disable no-useless-escape */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import './subscribe.scss';

const Subscribe = (props) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [successText, setSuccessText] = useState(false);
  const { reset } = useForm();

  const submitEmail = () => {
    axios.post('https://etf-cms.herokuapp.com/newsletters', { email });
    setSuccessText(true);
    setTimeout(() => {
      setSuccessText(false);
    }, 3000);
    reset();
  };

  const validateEmail = (text) => {
    const validation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (!validation.test(text)) {
      setErrorMessage('Unesite validan email');
      return false;
    }
    setButtonDisabled(false);
    setErrorMessage('');
    submitEmail();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateEmail(email);
  };

  const handleEmailChange = (val) => {
    if (val === '') {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
    setEmail(val);
  };

  return (
    <div className="subscribe-main-container col-lg-8 col-xl-7 col-md-12 mx-auto">
      <div className="card mt-4 mt-lg-5 newsletter-container border-0 d-lg-flex justify-content-center mx-lg-auto mb-5 col-lg-12 col col-md-11 mx-auto">
        <img src={props.data.image[0].url} className="card-img" alt="..." />
        <form
          className="card-img-overlay mobile-content mx-auto col-sm-11"
        >
          <p className="title-newsletter mobile-title col-sm-11 ms-1 me-1 ms-lg-4 me-lg-0 mx-auto mb-5 mt-3">
            {props.data.title}
          </p>
          <div className="newsletter-input input-group mb-2 mt-3 mx-auto col-lg-5 w-100 w-lg-50">
            <input
              type="text"
              name="email"
              id="email"
              className="form-control shadow-none ps-1 bg-transparent border-0"
              placeholder={props.data.placeholder}
              aria-label={props.data.placeholder}
              aria-describedby="button-addon2"
              onChange={(e) => handleEmailChange(e.target.value)}
            />
          </div>
          {errorMessage && (
          <p className="error-message">
            {errorMessage}
          </p>
          )}
          <div className="btn-container mx-auto">
            <button
              className="btn button-mobile shadow-none mt-2 mx-auto d-flex ps-1"
              type="button"
              id="button-addon2"
              disabled={buttonDisabled}
              onClick={(e) => handleSubmit(e)}
            >
              {successText ? props.data.sent : props.data.confirm}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
Subscribe.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    confirm: PropTypes.string.isRequired,
    sent: PropTypes.string.isRequired,
    image: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default Subscribe;
