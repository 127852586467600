import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Carousel from 'react-bootstrap/Carousel';

import Info from './info';
import HeadOfETF from './headOfETF';
import Services from './services';
import FacultyStructure from './facultyStructure';

import './aboutUniversity.scss';

const AboutUniversity = (props) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div>
      <div className="container main-container col-12 col-lg-6 my-5">
        <div className="text-container col-12 col-lg-8 mx-auto">
          <h3 className="col-12 col-lg-9 mb-4 mb-lg-5 fs-5">{props.data.title}</h3>
          <p className="description">{props.data.description}</p>
          <ReactMarkdown className="about-content mt-4 ps-1 pt-2 d-none mx-auto d-lg-block">
            {props.data.text}
          </ReactMarkdown>
          {/* mobile-view */}
          <ReactMarkdown className="about-content-mobile mt-5 d-lg-none">
            {isReadMore
              ? `${props.data.text.slice(0, 390)}...`
              : props.data.text}
          </ReactMarkdown>
          {/* <!-- visible on mobile only --> */}
          <button
            type="button"
            className="btn d-lg-none col-12 d-flex mt-4 justify-content-center"
            onClick={toggleReadMore}
          >
            {isReadMore ? props.data.button : props.data.buttonLess}
          </button>
        </div>
        <div className="about-slider my-5 col-lg-8 mx-auto">
          <Carousel className="carousel" controls={false}>
            {props.data.images.map((item) => (
              <Carousel.Item className="carousel-inner" key={item.id}>
                <img src={item.image.url} className="d-block w-100" alt="..." />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <Info infoData={props} />
        <HeadOfETF headData={props} />
        <Services servicesData={props} />
        <FacultyStructure structureData={props} />
      </div>
    </div>
  );
};

AboutUniversity.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    buttonLess: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    images: PropTypes.arrayOf({
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    structureAndManagement: PropTypes.arrayOf({
      structureContent: PropTypes.string.isRequired,
      structure: PropTypes.string.isRequired,
      leadershipContent: PropTypes.string.isRequired,
      leadership: PropTypes.string.isRequired,
      facultyCouncilContent: PropTypes.string.isRequired,
      facultyCouncil: PropTypes.string.isRequired,
      accreditationsOfStudyProgramsContent: PropTypes.string.isRequired,
      accreditationsOfStudyPrograms: PropTypes.string.isRequired,
    }).isRequired,
    dean: PropTypes.string.isRequired,
    Identification: PropTypes.string.isRequired,
    bank: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    budgetOrganization: PropTypes.string.isRequired,
    budgetOrganizationNumber: PropTypes.string.isRequired,
    deanMail: PropTypes.string.isRequired,
    deanName: PropTypes.string.isRequired,
    deanTitle: PropTypes.string.isRequired,
    deanforAcademicAffairs: PropTypes.string.isRequired,
    deanforAcademicAffairsMail: PropTypes.string.isRequired,
    deanforAcademicAffairsName: PropTypes.string.isRequired,
    deanforAcademicAffairsTitle: PropTypes.string.isRequired,
    deansOffice: PropTypes.string.isRequired,
    deansOfficeMail: PropTypes.string.isRequired,
    deansOfficeName: PropTypes.string.isRequired,
    deansOfficePhone: PropTypes.string.isRequired,
    deposit: PropTypes.string.isRequired,
    depositAccount: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    fax: PropTypes.string.isRequired,
    faxNumber: PropTypes.string.isRequired,
    financialService: PropTypes.string.isRequired,
    financialServiceMail: PropTypes.string.isRequired,
    financialServiceName: PropTypes.string.isRequired,
    financialServicePhone: PropTypes.string.isRequired,
    humanResources: PropTypes.string.isRequired,
    humanResourcesMail: PropTypes.string.isRequired,
    humanResourcesName: PropTypes.string.isRequired,
    humanResourcesPhone: PropTypes.string.isRequired,
    ictCenter: PropTypes.string.isRequired,
    ictCenterMail: PropTypes.string.isRequired,
    ictCenterName: PropTypes.string.isRequired,
    ictCenterPhone: PropTypes.string.isRequired,
    identificationNumber: PropTypes.string.isRequired,
    mail: PropTypes.string.isRequired,
    maintenanceService: PropTypes.string.isRequired,
    maintenanceServiceMail: PropTypes.string.isRequired,
    maintenanceServiceName: PropTypes.string.isRequired,
    maintenanceServicePhone: PropTypes.string.isRequired,
    secretary: PropTypes.string.isRequired,
    secretaryMail: PropTypes.string.isRequired,
    secretaryName: PropTypes.string.isRequired,
    secretaryPhone: PropTypes.string.isRequired,
    studentService: PropTypes.string.isRequired,
    studentServiceMail: PropTypes.string.isRequired,
    studentServiceName: PropTypes.string.isRequired,
    studentServicePhone: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
    DeansOffice: PropTypes.string.isRequired,
    telDeansOfficeNumber: PropTypes.string.isRequired,
    telDeansOffice: PropTypes.string.isRequired,
    telNumber: PropTypes.string.isRequired,
    viceDeanforGeneralAffairs: PropTypes.string.isRequired,
    viceDeanforGeneralAffairsMail: PropTypes.string.isRequired,
    viceDeanforGeneralAffairsName: PropTypes.string.isRequired,
    viceDeanforGeneralAffairsTitle: PropTypes.string.isRequired,
    viceDeanforResearch: PropTypes.string.isRequired,
    viceDeanforResearchMail: PropTypes.string.isRequired,
    viceDeanforResearchName: PropTypes.string.isRequired,
    viceDeanforResearchTitle: PropTypes.string.isRequired,
    webMaster: PropTypes.string.isRequired,
    webMasterName: PropTypes.string.isRequired,
    webMasterPhone: PropTypes.string.isRequired,
    webMasterMail: PropTypes.string.isRequired,
    publicProcurement: PropTypes.string.isRequired,
    publicProcurementName: PropTypes.string.isRequired,
    publicProcurementPhone: PropTypes.string.isRequired,
    publicProcurementMail: PropTypes.string.isRequired,
    viceDeanInternational: PropTypes.string.isRequired,
    viceDeanInternationalTitle: PropTypes.string.isRequired,
    viceDeanInternationalName: PropTypes.string.isRequired,
    viceDeanInternationalMail: PropTypes.string.isRequired,
    viceDeanStudent: PropTypes.string.isRequired,
    viceDeanStudentTitle: PropTypes.string.isRequired,
    viceDeanStudentName: PropTypes.string.isRequired,
    viceDeanStudentMail: PropTypes.string.isRequired,
    web: PropTypes.string.isRequired,
    webLink: PropTypes.string.isRequired,
  }).isRequired,
};

export default AboutUniversity;
