/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Sections from '../components/sections';
import SEO from '../components/seo';

const DynamicPage = ({ data, pageContext }) => {
  const {
    contentSections, metadata, locale,
  } = data.strapiPages;
  const global = data.strapiGlobal;
  return (
    <>
      <SEO seo={metadata} global={global} />
      <Layout global={global} pageContext={pageContext}>
        <Sections
          pageContext={pageContext}
          sections={contentSections}
          globalData={global}
          locale={locale}
        />
      </Layout>
    </>
  );
};

export default DynamicPage;

export const query = graphql`
  fragment GlobalData on StrapiGlobal {
    footer {
      ETFDepartments {
        title
        id
      }
      ETFDepartmentLinks {
        url
        title
        id
      }
      externLinks {
        id
        title
        url
      }
      address {
        title
        Description
        description
      }
      contact {
        Fax
        Email
        Tel
        title
      }
      copyright {
        title
      }
      Social {
        id
        Link
      }
    }

    id
    metadata {
      metaDescription
      metaTitle
    }
    navbar {
      mainLinks {
        id
        url
        title
      }
      secondLinks {
        id
        title
        url
      }
      ETFDepartment {
        url
        title
      }
      regulations {
        title
        url
      }
    }
  }

  query DynamicPageQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiPages(id: { eq: $id }) {
      slug
      ShortName
      contentSections
      locale
      metadata {
        metaDescription
        metaTitle
      }
      localizations {
        id
        locale
      }
    }
  }
`;
