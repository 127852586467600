import { graphql, useStaticQuery } from 'gatsby';

const useBlogData = () => {
  const {
    allStrapiBlogs: { nodes: blogs },
  } = useStaticQuery(graphql`
    {
      allStrapiBlogs(sort: { fields: date, order: DESC }) {
        nodes {
          id
          content
          date
          description
          image {
            url
          }
          locale
          slug
          title
          categories
          category
          blogId
          info_blog{
            title
          }
        }
      }
    }
  `);
  return blogs;
};

export default useBlogData;
