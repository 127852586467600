/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { BsArrowRight } from 'react-icons/bs';

import CustomLink from '../../CustomLink';
import { localizePath } from '../../../utils/localize';

import './associatesAutomation.scss';

const AssociatesAutomation = (props) => {
  const { pageContext } = props;
  return (
    <div>
      <div className="main-department-container">
        <div className="department-title-container d-flex justify-content-between">
          <h3 className="department-title">{props.data.titleOne}</h3>
          <p className="go-to-associates my-auto">
            <CustomLink
              link={{
                url: `${localizePath({
                  ...props,
                  ...pageContext,
                  isPreview: false,
                  slug: props.data.departmentSlug,
                })}`,
              }}
              to={`/${props.data.departmentSlug}`}
            >
              {props.data.titleTwo}
            </CustomLink>
          </p>
        </div>
        <div className="associates-main-wrapper">
          {props.data.content.map((item) => (
            <CustomLink
              link={{
                url: `${localizePath({
                  ...item,
                  ...pageContext,
                  isPreview: false,
                  slug: item.slug,
                })}`,
              }}
              to={`/${item.slug}`}
            >
              <div className="associates-wrapper" key={item.id}>
                <img className="associate-img" src={item.image.url} alt="" />
                <div className="associates-info">
                  <p className="associate-name">{item.name}</p>
                  <a className="associate-email" href={`mailto:${item.email}`}>
                    {item.email}
                  </a>
                  <p className="associate-see-more">
                    {item.readMore}
                    <span className="right-arrow my-auto">
                      <BsArrowRight />
                    </span>
                  </p>
                </div>
              </div>
            </CustomLink>
          ))}
        </div>
      </div>
    </div>
  );
};
AssociatesAutomation.propTypes = {
  data: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    titleOne: PropTypes.string.isRequired,
    titleTwo: PropTypes.string.isRequired,
    departmentSlug: PropTypes.string.isRequired,
    content: PropTypes.arrayOf({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      readMore: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default AssociatesAutomation;
