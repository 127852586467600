/* eslint-disable no-dupe-keys */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';

import Hero from './Sections/HeroSection';
import Information from './Sections/Information';
import Cycles from './Sections/Cycles';
import Statistic from './Sections/Statistic';
import Department from './Sections/Department';
import Subscribe from './Sections/Subscribe';
import HeroImage from './Sections/HeroImage';
import DescriptionAutomation from './Sections/DescriptionAutomation';
import AssociatesAutomation from './Sections/AssociatesAutomation';
import AboutAutomation from './Sections/AboutAutomation';
import AboutUniversity from './Sections/AboutUniversity';
import InfoBlog from './Sections/InfoBlog';
import Professors from './Sections/Professors';
import Contact from './Sections/Contact';
import Study from './Sections/Study';
import Sciences from './Sections/Sciences';
import Associate from './Sections/Associate';
import Regulation from './Sections/Regulation';

const sectionComponents = {
  'sections.hero': Hero,
  'sections.informations': Information,
  'sections.cycles': Cycles,
  'sections.statistics': Statistic,
  'sections.departments': Department,
  'sections.subscribe': Subscribe,
  'sections.hero-img': HeroImage,
  'sections.description': DescriptionAutomation,
  'sections.associates': AssociatesAutomation,
  'sections.about': AboutAutomation,
  'sections.about-history': AboutUniversity,
  'sections.info-blog': InfoBlog,
  'sections.etfassociates': Professors,
  'sections.contact': Contact,
  'sections.study-cycles': Study,
  'sections.sciences': Sciences,
  'sections.single-associate': Associate,
  'sections.regulation': Regulation,
};

const Section = ({ sectionData, locale, pageContext }) => {
  const SectionComponent =
    sectionComponents[sectionData.strapi_component || sectionData.__component];
  if (!SectionComponent) {
    return null;
  }
  return (
    <SectionComponent
      pageContext={pageContext}
      data={sectionData}
      locale={locale}
    />
  );
};

const Sections = (props) => {
  const lang = props.locale;
  return (
    <div className="flex flex-col">
      {props.sections.map((section, i) => (
        <Section
          pageContext={props.pageContext}
          sectionData={section}
          locale={lang}
          key={`${section.strapi_component}${(section.id, i)}`}
        />
      ))}
    </div>
  );
};
Section.propTypes = {
  sectionData: PropTypes.shape({
    strapi_component: PropTypes.string.isRequired,
    __component: PropTypes.string,
  }).isRequired,
};

Sections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
};

export default Sections;
