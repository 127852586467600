import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import ReactMarkdown from 'react-markdown';

import { localizePath } from '../../../utils/localize';
import CustomLink from '../../CustomLink';

import useBlogData from '../../../queries/blogsQueries';
import useInfoBlogData from '../../../queries/infoBlogQueries';

import './infoblog.scss';
import './infoHero.scss';

const InfoBlog = (props) => {
  const { pageContext } = props;
  const blogsData = useBlogData();
  const infoBlogsData = useInfoBlogData();
  const langBlogsData = blogsData.filter(
    (item) => item.locale === props.locale,
  );
  const langInfoBlogsData = infoBlogsData.filter(
    (item) => item.locale === props.locale,
  );
  return (
    <div className="notification-container">
      <div className="card hero-container border-0 text-white">
        <img
          src={props.data.heroimage.image.url}
          className="card-img"
          alt="..."
        />
        <div className="card-img-overlay mx-auto col-lg-8">
          <h5 className="card-title hero-title-one">
            {props.data.heroimage.title}
          </h5>
          <p className="card-text hero-title-two d-none d-lg-block mt-4">
            {props.data.heroimage.titleTwo}
          </p>
          {/* visible on mobile */}
          <p className="card-text hero-title-two-mobile d-lg-none mt-3">
            {props.data.heroimage.titleTwo}
          </p>
        </div>
      </div>
      <div className="main-wrapper col-lg-8 col-12 d-lg-flex mx-auto">
        <div className="left-container-wrapper col-lg-3 col-12 py-lg-5">
          <div className="button-wrapper">
            {langInfoBlogsData.map((item) => (
              <CustomLink
                className="language-text"
                link={{
                  ...item,
                  url: `${localizePath({
                    ...pageContext,
                    isPreview: false,
                    slug: item.slug,
                    locale: item.locale,
                  })}`,
                }}
                to={`/${item.slug}`}
              >
                <button
                  className="shadow-none py-3 px-2"
                  type="button"
                  key={item.id}
                >
                  {item.title}
                </button>
              </CustomLink>
            ))}
          </div>
        </div>
        <div className="right-container-wrapper py-lg-3 ms-lg-2 col-lg-9 col-xl-8 col-12">
          <div className="content-wrapper mx-lg-4">
            <div className="main-container col-12 mt-lg-4">
              <div className="text-container col-12 col-lg-10 col-md-11 mx-auto">
                {langBlogsData.map((item) => (
                  <div className="mb-3 bg-light p-3" key={item.id}>
                    <CustomLink
                      className="language-text"
                      link={{
                        ...item,
                        url: `${localizePath({
                          ...pageContext,
                          isPreview: false,
                          slug: item.slug,
                          locale: item.locale,
                        })}`,
                      }}
                      to={`/${item.slug}`}
                    >
                      <div className="d-md-flex">
                        <img
                          className="col-12 d-none d-md-block col-md-5 col-lg-3 me-2 pt-5"
                          src={item?.image?.url}
                          alt=""
                        />
                        <h3 className="col-12 col-md-7 mx-auto sm:mt-5">
                          {item.title}
                        </h3>
                      </div>
                      <p className="description mt-4">
                        <Moment format="DD.MM.YYYY">{item.date}</Moment>
                      </p>
                      <ReactMarkdown className="main-content-text mt-4">
                        {`${item?.content?.substring(0, 225)}...`}
                      </ReactMarkdown>
                    </CustomLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
InfoBlog.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    blog: PropTypes.arrayOf({
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    heroimage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      titleTwo: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    buttons: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,

  locale: PropTypes.string.isRequired,
};

export default InfoBlog;
