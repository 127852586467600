/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Carousel from 'react-bootstrap/Carousel';

import './hero.css';

const Hero = (props) => (
  <div className="carousel slider-spacing">
    <Carousel className="carousel-inner" controls={false}>
      {props.data.heroSlider.map((item) => (
        <Carousel.Item className="carousel-item" key={item.id}>
          <div className="image-wrapper">
            <img src={item.image.url} className="d-block w-100" alt="..." />
          </div>
          <Carousel.Caption className="carousel-caption col flex-column d-md-block d-flex ms-auto">
            <p className="title-desktop col-12">{item.title}</p>
            <ReactMarkdown className="slider-title-desktop pt-4  col-lg-10 mx-auto">
              {item.description}
            </ReactMarkdown>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  </div>
);

Hero.propTypes = {
  data: PropTypes.shape({
    heroSlider: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default Hero;
