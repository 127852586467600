import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import useSciencesData from '../../../queries/sciencesQueries';
import useScienceSubFieldData from '../../../queries/scienceSubFieldQuery';

import '../Study/studies.scss';

const Sciences = (props) => {
  const sciences = useSciencesData();
  const scienceSubField = useScienceSubFieldData();
  const langSciencesData = sciences.filter(
    (item) => item.locale === props.locale,
  );
  const langScienceSubFieldData = scienceSubField.filter(
    (item) => item.locale === props.locale,
  );
  return (
    <div>
      <div className="main-wrapper col-lg-7 col-12 d-lg-flex mx-auto">
        <div className="left-container-wrapper col-lg-3 col-12 py-5">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {langSciencesData.map((science) => (
              <div className="accordion-item" key={science.id}>
                <h2 className="accordion-header" id={science.headingDropdown}>
                  <button
                    className="shadow-none accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${science.accordionId}`}
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <p>{science.title}</p>
                  </button>
                </h2>
                <div
                  id={science.accordionId}
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className="group-links">
                      {langScienceSubFieldData
                        .filter(
                          (item) => item.scienceSubId === science.scienceId,
                        )
                        .map((item) => (
                          <li className="links pt-1" key={item.id}>
                            <Link className="link" to={`/${item.slug}`}>
                              {item.title}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="right-container-wrapper py-5 ms-lg-2 col-lg-9 col-12">
          <div className="title-container mb-5 container">
            <h4 className="title-text text-center">{props.data.description}</h4>
          </div>
          <div className="content-wrapper container">
            <p className="content-text">{props.data.content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
Sciences.propTypes = {
  locale: PropTypes.string.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    scienceSubId: PropTypes.string.isRequired,
    science: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default Sciences;
