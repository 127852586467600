/* eslint-disable react/prop-types */
import React from 'react';
import ReactMarkdown from 'react-markdown';

import './aboutUniversity.scss';

const FacultyStructure = (props) => (
  <div>
    <div className="text-container col-12 col-lg-8 mx-auto">
      <h3 className="col-12 structure-title mt-5 col-lg-7 mb-4">
        {props.structureData.data.structureAndManagement.structure}
      </h3>
      <ReactMarkdown className="structure-content">
        {props.structureData.data.structureAndManagement.structureContent}
      </ReactMarkdown>
      <h3 className="col-12 structure-title mt-4 col-lg-7 mb-4">
        {props.structureData.data.structureAndManagement.leadership}
      </h3>
      <ReactMarkdown className="structure-content">
        {props.structureData.data.structureAndManagement.leadershipContent}
      </ReactMarkdown>
      <h3 className="col-12 structure-title mt-4 col-lg-7 mb-4">
        {props.structureData.data.structureAndManagement.facultyCouncil}
      </h3>
      <ReactMarkdown className="structure-content">
        {props.structureData.data.structureAndManagement.facultyCouncilContent}
      </ReactMarkdown>
      <h3 className="col-12 structure-title mt-4 col-lg-7 mb-4">
        {
          props.structureData.data.structureAndManagement
            .accreditationsOfStudyPrograms
        }
      </h3>
      <ReactMarkdown className="structure-content">
        {
          props.structureData.data.structureAndManagement
            .accreditationsOfStudyProgramsContent
        }
      </ReactMarkdown>
    </div>
  </div>
);

export default FacultyStructure;
