import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronRight } from 'react-icons/fi';
import './arrows.scss';

const RightArrow = (props) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div
      onClick={props.onClick}
      role="presentation"
      className="next_carousel_arrow"
      style={{
        transition: '.5s',
        color: isHovering ? '#121E2A' : '',
        border: isHovering ? '1px solid #121E2A' : '',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <FiChevronRight className="carousel_right_arrow" />
    </div>
  );
};

RightArrow.propTypes = {
  onClick: PropTypes.func,
};

RightArrow.defaultProps = {
  onClick: () => {},
};

export default RightArrow;
