/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import './showMoreButton.scss';

const ShowMoreButton = (props) => {
  const [isShowMore, setIsShowMore] = useState(true);

  const handleToggle = (e) => {
    props.setExpand(e);
    setIsShowMore(!isShowMore);
  };

  return (
    <div className="button-container col-lg-10 col-md-10 mx-auto d-flex justify-content-center">
      <button
        id={props.id}
        type="button"
        className="btn justify-content-center mb-5"
        onClick={handleToggle}
      >
        {isShowMore ? props.showMore : props.showLess}
      </button>
    </div>
  );
};
export default ShowMoreButton;
