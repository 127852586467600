/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';
import PropTypes from 'prop-types';

import './statistic.scss';

const Statistic = (props) => (
  <div>
    <div className="statistic-main-wrapper">
      <div className="statistic-title-container">
        <h3 className="statistic-title">{props.data.title}</h3>
        <p className="statistic-description">{props.data.description}</p>
      </div>
      <div className="statistic-card-container">
        {props.data.card.map((item) => (
          <div className="statistic-card" key={item.id}>
            <img className="statistic-icon" src={item.icon.url} alt="..." />
            <p className="statistic-number">{item.number}</p>
            <p className="statistic-text">
              {item.innerText}
            </p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

Statistic.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    card: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        ),
        innerText: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default Statistic;
