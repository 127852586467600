/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import './aboutAutomation.scss';

const AboutAutomation = (props) => (
  <div>
    <div className="about-main-container">
      <div className="about-title-container">
        <h3 className="about-title">{props.data.aboutTitle}</h3>
      </div>
      <div className="about-description-container">
        <p className="about-description">{props.data.title}</p>
        <p className="about-description-content">{props.data.description}</p>
      </div>
      <div className="about-automation-details-container d-md-flex ">
        <div className="about-image">
          <img src={props.data.image.url} alt="" />
        </div>
        <div className="about-automation-details d-md-flex ms-md-5 ">
          <div className="first-container">
            {props.data.contentLeft.map((item) => (
              <div className="inner-first-container" key={item.id}>
                <p className="number">{item.number}</p>
                <p className="title">{item.title}</p>
                <p className="text">{item.text}</p>
              </div>
            ))}
          </div>
          <div className="second-container">
            {props.data.contentRight.map((item) => (
              <div className="inner-second-container" key={item.id}>
                <p className="number">{item.number}</p>
                <p className="title">{item.title}</p>
                <p className="text">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);
AboutAutomation.propTypes = {
  data: PropTypes.shape({
    aboutTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    contentLeft: PropTypes.arrayOf({
      number: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    contentRight: PropTypes.arrayOf({
      number: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AboutAutomation;
