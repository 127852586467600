import React from 'react';
import PropTypes from 'prop-types';

import { localizePath } from '../../../utils/localize';
import CustomLink from '../../CustomLink';
import './department.scss';

const Department = (props) => {
  const { pageContext } = props;
  return (
    <div>
      <div className="intro-container col-lg-12">
        <div className="header">
          <div className="text mx-auto col col-lg-8 col-xl-6 col-xxl-12 ps-xxl-4 pe-xxl-4 text-center my-4">
            <h5>{props.data.title}</h5>
            <p className="d-lg-block d-none mt-4 col-xl-11 mx-auto">
              {props.data.description}
            </p>
            {/* visible on mobile */}
            <p className="description-mobile container d-lg-none mt-4">
              {props.data.description}
            </p>
          </div>
        </div>
      </div>
      <div className="department-container d-none d-lg-flex col-xl-6 col-lg-8 mx-auto flex-column">
        {props.data.containerDepartments.map((item) => (
          <div className="row my-2 mx-auto d-flex" key={item.id}>
            <div className="column-small  col-lg-3 d-none d-lg-block">
              <img className="image  d-flex" src={item.image.url} alt="..." />
            </div>
            <div className="column-big col-lg-8 col-xl-7 col-md-12">
              <CustomLink
                link={{
                  url: `${localizePath({
                    ...item,
                    ...pageContext,
                    isPreview: false,
                    locale: props.locale,
                    slug: item.departmentSlug,
                  })}`,
                }}
                to={`/${item.departmentSlug}`}
              >
                <div className="card">
                  <div className="card-body">
                    <span className="d-none d-lg-block arrow-department down-department" />
                    <h5 className="card-title">{item.containerTitle}</h5>
                    <div className="col-lg-10">
                      <p className="card-text d-none d-md-block col-lg-11">
                        {item.containerDescription.substring(0, 150)}
                      </p>
                    </div>
                  </div>
                </div>
              </CustomLink>
            </div>
          </div>
        ))}
      </div>
      {/* visible on mobile devices */}
      <div className="main-container col mx-auto col-xl-6 col-lg-7">
        <div className="justify-content-center gap-3 container mx-auto container-mobile-card d-block d-md-flex flex-nowrap d-lg-none">
          {props.data.containerDepartments.map((item) => (
            <div key={item.id}>
              <div className="card-mobile mx-auto col-sm-12 mb-5">
                <CustomLink
                  link={{
                    url: `${localizePath({
                      ...item,
                      ...pageContext,
                      isPreview: false,
                      locale: props.locale,
                      slug: item.departmentSlug,
                    })}`,
                  }}
                  to={`/${item.departmentSlug}`}
                >
                  <img
                    src={item.image.url}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body-mobile ps-3 col-11">
                    <p className="card-text title-mobile my-2">
                      {item.containerTitle}
                    </p>
                    <p className="card-text content-mobile">
                      {item.containerDescription}
                    </p>
                  </div>
                </CustomLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Department.propTypes = {
  locale: PropTypes.string.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    containerDepartments: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        departmentSlug: PropTypes.string.isRequired,
        containerTitle: PropTypes.string.isRequired,
        containerDescription: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default Department;
