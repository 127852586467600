/* eslint-disable react/prop-types */
import React from 'react';

import './info.scss';

const Info = (props) => (
  <div>
    <hr className="col-lg-8 col mx-auto" />
    <div className="my-3 info-wrapper d-lg-flex col-lg-8 mx-auto">
      <div className="col left-content">
        <div className="d-flex">
          <p className="title">{`${props.infoData.data.tel}: `}</p>
          <a className="title-content" href={`tel:${props.infoData.data.telNumber}`}>
            {props.infoData.data.telNumber}
          </a>
        </div>
        <div className="d-flex">
          <p className="title">{`${props.infoData.data.telDeansOffice}:`}</p>
          <a
            className="title-content"
            href={`tel:${props.infoData.data.telDeansOfficeNumber}`}
          >
            {props.infoData.data.telDeansOfficeNumber}
          </a>
        </div>
        <div className="d-flex">
          <p className="title">{`${props.infoData.data.fax}:`}</p>
          <a className="title-content" href={`tel:${props.infoData.data.faxNumber}`}>
            {props.infoData.data.faxNumber}
          </a>
        </div>
        <div className="d-flex">
          <p className="title">{`${props.infoData.data.email}:`}</p>
          <a className="title-content" href={`mailto:${props.infoData.data.mail}`}>
            {props.infoData.data.mail}
          </a>
        </div>
        <div className="d-flex">
          <p className="title">{`${props.infoData.data.web}:`}</p>
          <a className="title-content" href={props.infoData.data.webLink}>
            {props.infoData.data.webLink}
          </a>
        </div>
      </div>
      <div className="col right-content">
        <div className="d-flex">
          <p className="title ms-lg-3">{`${props.infoData.data.Identification}:`}</p>
          <p className="title-content">{props.infoData.data.identificationNumber}</p>
        </div>
        <div className="d-flex">
          <p className="title ms-lg-3">{`${props.infoData.data.budgetOrganization}:`}</p>
          <p className="title-content">
            {props.infoData.data.budgetOrganizationNumber}
          </p>
        </div>
        <div className="d-flex">
          <p className="title ms-lg-3">{`${props.infoData.data.deposit}:`}</p>
          <p className="title-content">{props.infoData.data.depositAccount}</p>
        </div>
        <div className="d-flex">
          <p className="title ms-lg-3">{`${props.infoData.data.bank}:`}</p>
          <p className="title-content">{props.infoData.data.bankName}</p>
        </div>
      </div>
    </div>
    <hr className="col-lg-8 mx-auto" />
  </div>
);

export default Info;
