import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import './descriptionAutomation.scss';

const DescriptionAutomation = (props) => (
  <div>
    <div className="description-main-container">
      <div className="description-title-container">
        <h3 className="description-title">
          {props.data.title}
        </h3>
        <p className="description-subtitle">
          {props.data.description}
        </p>
      </div>
      <div className="description-wrapper">
        <div className="description-left-container">
          {props.data.contentLeft.map((item) => (
            <div className="mt-3" key={item.id}>
              <ReactMarkdown>
                {item.content}
              </ReactMarkdown>
              <hr className="mt-3" />

            </div>
          ))}
        </div>
        <div className="description-right-container">
          {props.data.contentRight.map((item) => (
            <div className="mt-3" key={item.id}>
              <ReactMarkdown>
                {item.content}
              </ReactMarkdown>
              <hr className="mt-3" />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
DescriptionAutomation.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contentLeft: PropTypes.arrayOf({
      text: PropTypes.string.isRequired,
    }).isRequired,
    contentRight: PropTypes.arrayOf({
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DescriptionAutomation;
