/* eslint-disable react/prop-types */
import React from 'react';

import './info.scss';

const HeadOfETF = (props) => (
  <div>
    <div className="my-3 info-wrapper d-lg-flex col col-lg-8 mx-auto">
      <div className="col left-content">

	<div className="d-flex">
          <p className="title">{`${props.headData.data.dean}: `}</p>
        </div>
        <div>
          <p className="title-content-Head">{`${props.headData.data.deanTitle} ${props.headData.data.deanName}`}</p>
          <a
            className="title-content-Head"
            href={`mailto:${props.headData.data.deanMail}`}
          >
            {props.headData.data.deanMail}
          </a>
        </div>

      </div>
      <div className="col right-content">

	<div className="d-flex">
          <p className="title ms-lg-3">{`${props.headData.data.viceDeanforGeneralAffairs}:`}</p>
        </div>
        <div>
          <p className="title-content-Head ms-lg-3">{`${props.headData.data.viceDeanforGeneralAffairsTitle} ${props.headData.data.viceDeanforGeneralAffairsName}`}</p>
          <a
            className="title-content-Head ms-lg-3"
            href={`mailto:${props.headData.data.viceDeanforGeneralAffairsMail}`}
          >
            {props.headData.data.viceDeanforGeneralAffairsMail}
          </a>
        </div>

	<div className="d-flex">
          <p className="title ms-lg-3">{`${props.headData.data.deanforAcademicAffairs}:`}</p>
        </div>
        <div>
          <p className="title-content-Head ms-lg-3">{`${props.headData.data.deanforAcademicAffairsTitle} ${props.headData.data.deanforAcademicAffairsName}`}</p>
          <a
            className="title-content-Head ms-lg-3"
            href={`mailto:${props.headData.data.deanforAcademicAffairsMail}`}
          >
            {props.headData.data.deanforAcademicAffairsMail}
          </a>
        </div>

	<div className="d-flex">
          <p className="title ms-lg-3">{`${props.headData.data.viceDeanforResearch}:`}</p>
        </div>
        <div>
          <p className="title-content-Head ms-lg-3">{`${props.headData.data.viceDeanforResearchTitle} ${props.headData.data.viceDeanforResearchName}`}</p>
          <a
            className="title-content-Head ms-lg-3"
            href={`mailto:${props.headData.data.viceDeanforResearchMail}`}
          >
            {props.headData.data.viceDeanforResearchMail}
          </a>
        </div>

        <div className="d-flex">
          <p className="title ms-lg-3">{`${props.headData.data.viceDeanInternational}:`}</p>
        </div>
        <div>
          <p className="title-content-Head ms-lg-3">{`${props.headData.data.viceDeanInternationalTitle} ${props.headData.data.viceDeanInternationalName}`}</p>
          <a
            className="title-content-Head ms-lg-3"
            href={`mailto:${props.headData.data.viceDeanInternationalMail}`}
          >
            {props.headData.data.viceDeanInternationalMail}
          </a>
        </div>

	<div className="d-flex">
          <p className="title ms-lg-3">{`${props.headData.data.viceDeanStudent}:`}</p>
        </div>
        <div>
          <p className="title-content-Head ms-lg-3">{` ${props.headData.data.viceDeanStudentName}`}</p>
          <a
            className="title-content-Head ms-lg-3"
            href={`mailto:${props.headData.data.viceDeanStudentMail}`}
          >
            {props.headData.data.viceDeanStudentMail}
          </a>
        </div>

      </div>
    </div>
    <hr className="col-lg-8 mx-auto" />
  </div>
);

export default HeadOfETF;
