import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import CustomLink from '../../CustomLink';

import { localizePath } from '../../../utils/localize';

import useCyclesData from '../../../queries/cyclesQueries';
import useStudiesData from '../../../queries/studiesQueries';

import './studies.scss';

const Studies = (props, pageContext) => {
  const cycles = useCyclesData();
  const studies = useStudiesData();
  const langCyclesData = cycles.filter((item) => item.locale === props.locale);
  const langStudiesData = studies.filter(
    (item) => item.locale === props.locale,
  );
  return (
    <div>
      <div className="main-wrapper col-lg-7 col-12 d-lg-flex mx-auto">
        <div className="left-container-wrapper col-lg-3 col-12 py-5">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {langCyclesData.map((cycle) => (
              <div className="accordion-item" key={cycle.id}>
                <h2 className="accordion-header" id={cycle.headingDropdown}>
                  <button
                    className="shadow-none accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${cycle.accordionId}`}
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <p>{cycle.title}</p>
                  </button>
                </h2>
                <div
                  id={cycle.accordionId}
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className="group-links">
                      {langStudiesData
                        .filter((item) => item.studiesId === cycle.cycleId)
                        .map((item) => (
                          <li className="links pt-1" key={item.id}>
                            <CustomLink
                              className="language-text"
                              link={{
                                url: `${localizePath({
                                  ...item,
                                  ...pageContext,
                                  isPreview: false,
                                  slug:
                                    item.locale === 'bs'
                                      ? `${item.slug}`.slice(0)
                                      : item.slug,
                                })}`,
                              }}
                              to={`/${item.slug}`}
                            >
                              <p className="link">{item.title}</p>
                            </CustomLink>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="right-container-wrapper py-5 ms-lg-2 col-lg-9 col-12">
          <div className="title-container mb-5 container">
            <h4 className="title-text text-center">{props.data.description}</h4>
          </div>
          <div className="content-wrapper container">
            <ReactMarkdown className="content-text">
              {props.data.content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};
Studies.propTypes = {
  locale: PropTypes.string.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    studiesId: PropTypes.string.isRequired,
    cycle: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default Studies;
