import { graphql, useStaticQuery } from 'gatsby';

const useAssociatesData = () => {
  const {
    allStrapiAssociates: { nodes: associates },
  } = useStaticQuery(graphql`
    {
      allStrapiAssociates(sort: {order: ASC, fields: strapiId}) {
        nodes {
          metaData {
            id
            metaDescription
            metaTitle
          }
          id
          locale
          mail
          name
          surname
          biography
          biographyTitle
          reference
          referenceTitle
          professorsTitle
          readMore
          slug
          affiliation
          affiliationContent
          associatedDepartment
          contactInformation
          curriculumVitae
          curriculumVitaeSite
          departmentContent
          fax
          faxNumber
          personalWeb
          personalWebSite
          phone
          phoneNumber
          position
          positionContent
          department {
            associate
            id
            locale
          }
          localizations {
            id
            locale
          }
          image {
            url
          }
        }
      }
    }
  `);
  return associates;
};

export default useAssociatesData;
