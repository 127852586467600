import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useAssociatesData from '../../../queries/associatesQueries';
import AssociatesCardItem from '../../AssociatesCardItem/AssociatesCardItem';
import ShowMoreButton from '../../AssociatesCardItem/showMoreButton';

import './professors.scss';

const Professors = (props) => {
  const associates = useAssociatesData();
  const [expanded, setExpanded] = useState({
    1: 3,
    2: 3,
    3: 3,
    4: 3,
    5: 3,
    6: 3,
    7: 3,
    8: 3,
  });
  const toggleProfessors = (e) => {
    const departmentId = e.target.id;
    if (expanded[departmentId] === 3) {
      setExpanded({ ...expanded, [departmentId]: associates.length });
    } else {
      setExpanded({ ...expanded, [departmentId]: 3 });
    }
  };

  return (
    <div>
      <div className="professors-wrapper container">
        <div className="main-container col-12 col-lg-8 mx-auto">
          <div className="title-container mt-0 d-lg-flex">
            <div className="title-wrapper col-lg-4">
              <p className="total-number mt-xl-3">{associates.length / 2}</p>
              <p className="total-number-title">
                {props.data.totalNumberTitle}
              </p>
            </div>
            <div className="description-container ms-auto col-lg-7 me-lg-2 me-xl-0">
              <p className="total-number-description">
                {props.data.totalDescription}
              </p>
            </div>
          </div>
        </div>
        <div className="main-associates-container col-12 col-lg-8 mx-auto">
          <div className="associates-container flex-wrap col-12 d-lg-flex">
            {props.data.departments.map((departmentName) => (
              <div key={departmentName.id}>
                <div className="associates-title-wrapper">
                  <div className="department-title-wrapper">
                    <p className="department-title">
                      {departmentName.department}
                    </p>
                  </div>
                  <div className="department-total-number-wrapper mt-4 mb-2 align-items-center d-flex">
                    <p className="department-total-title ms-auto">
                      {props.data.deparmtentTotalTitle}
                    </p>
                    <p className="department-total-number">
                      {
                        associates.filter(
                          (item) => item.department.id === departmentName.id,
                        ).length
                      }
                    </p>
                  </div>
                </div>
                <div className="associates-main-wrapper col-12 flex-wrap mx-auto d-md-flex ">
                  {associates
                    .filter((item) => item.department.id === departmentName.id)
                    .map((item) => (
                      <AssociatesCardItem
                        associates={associates}
                        props={item}
                        key={item.id}
                      />
                    ))
                    .slice(0, expanded[departmentName.id])}
                  <ShowMoreButton
                    id={departmentName.id}
                    setExpand={toggleProfessors}
                    showLess={props.data.departmentLessNumber}
                    showMore={props.data.departmentTotalNumber}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Professors.propTypes = {
  data: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    totalNumberTitle: PropTypes.string.isRequired,
    totalDescription: PropTypes.string.isRequired,
    deparmtentTotalTitle: PropTypes.string.isRequired,
    departmentTotalNumber: PropTypes.string.isRequired,
    departmentLessNumber: PropTypes.string.isRequired,
    titleTwo: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    departments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        department: PropTypes.string.isRequired,
      }),
    ),
    associatesCards: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        readMore: PropTypes.string.isRequired,
        mail: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default Professors;
