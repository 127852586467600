import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import useAssociatesData from '../../../queries/associatesQueries';
import '../../AssociatesDetailPage/associatesDetails.scss';

const AssociatesDetailPage = () => {
  const associates = useAssociatesData();
  return (
    <div>
      {associates.map((item) => (
        <div className=" main-wrapper mx-auto col-12 col-lg-8" key={item.id}>
          <div className="container title-container d-lg-flex mt-4">
            <div className="name-wrapper mb-3 col col-lg-8 mt-3 d-lg-flex">
              <p className="professor-title mt-2">{item.professorsTitle}</p>
              <p className="name ms-lg-3">{`${item.name} ${item.surname}`}</p>
            </div>
            <div className="img-container justify-content-center d-flex col col-lg-4 ms-lg-auto">
              <img className="rounded-circle " src={item?.image?.url} alt="" />
            </div>
          </div>
          <div className="container about-professor">
            <hr className="mt-4" />
            <h5 className="mt-3">{item.biographyTitle}</h5>
            <ReactMarkdown className="bio-content">{item.biography}</ReactMarkdown>
          </div>
        </div>
      ))}
    </div>
  );
};
AssociatesDetailPage.propTypes = {
  data: PropTypes.shape({
    professorsTitle: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    biographyTitle: PropTypes.string.isRequired,
    biography: PropTypes.string.isRequired,
    referenceTitle: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    mail: PropTypes.string.isRequired,
    readMore: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default AssociatesDetailPage;
