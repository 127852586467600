import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronLeft } from 'react-icons/fi';
import './arrows.scss';

const LeftArrow = (props) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div
      onClick={props.onClick}
      role="presentation"
      className="prev_carousel_arrow"
      style={{
        transition: '.5s',
        color: isHovering ? '#121E2A' : '',
        border: isHovering ? '1px solid #121E2A' : '',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <FiChevronLeft className="carousel_left_arrow" />
    </div>
  );
};

LeftArrow.propTypes = {
  onClick: PropTypes.func,
};

LeftArrow.defaultProps = {
  onClick: () => {},
};

export default LeftArrow;
