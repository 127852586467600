/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import ReactMarkdown from 'react-markdown';
import { Carousel } from '@trendyol-js/react-carousel';
import { MdOutlineCalendarToday } from 'react-icons/md';

import RightArrow from '../../Arrows/rightArrow';
import LeftArrow from '../../Arrows/leftArrow';
import useBlogData from '../../../queries/blogsQueries';

import './information.scss';

const Information = (props) => {
  const blogData = useBlogData();
  const langBlogData = blogData.filter((item) => item.locale === props.locale);
  return (
    <div>
      <div className="information-main-container col">
        <div className="information-title-container">
          <h3 className="information-title">{props.data.title}</h3>
          <h3 className="info-card-title-desktop ms-auto d-none d-md-block">
            {props.data.about}
          </h3>
        </div>
        <div className="info-container d-lg-flex justify-content-between">
          <Carousel
            className="info-slider"
            show={1}
            slide={1}
            responsive
            swiping
            leftArrow={<LeftArrow />}
            rightArrow={<RightArrow />}
          >
            {langBlogData.slice(0, 9).map((item) => (
              <div className="info-content-container" key={item.id}>
                <div className="info-content-wrapper">
                  <h3 className="info-title">
                    {item.title.length > 100
                      ? `${item.title.substring(0, 97)}...`
                      : item.title}
                  </h3>
                  <p className="info-description">
                    <Moment format="DD.MM.YYYY">{item.date}</Moment>
                  </p>
                  <ReactMarkdown className="info-text blog-content">
                    {item.content}
                  </ReactMarkdown>
                </div>
                <Link to={item.slug}>
                  <button
                    type="button"
                    className="info-btn btn rounded-0 mx-auto text-center"
                  >
                    {props.data.button}
                  </button>
                </Link>
              </div>
            ))}
          </Carousel>
          <div className="info-card-container ">
            <hr />
            <div className="info-card-title-wrapper">
              <h3 className="info-card-title d-md-none">{props.data.about}</h3>
            </div>
            <div>
              {langBlogData.slice(10, 13).map((item) => (
                <div className="info-card-wrapper">
                  <Link to={item.slug}>
                    <div className="info-card-box">
                      <div className="info-card-content-container">
                        <p className="info-box-date">
                          <MdOutlineCalendarToday className="icon mt-1 me-2" />
                          <Moment format="DD.MM.YYYY">{item.date}</Moment>
                        </p>
                        <p className="info-box-description">
                        </p>
                        <ReactMarkdown className="info-box-content">
                          {item.title.length > 110
                            ? `${item.title.substring(0, 112)}...`
                            : item.title}
                        </ReactMarkdown>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Information.propTypes = {
  locale: PropTypes.string.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    infoSlider: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        aboutInfo: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      }),
    ),
    infoCard: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        titleTwo: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default Information;
